import { render, staticRenderFns } from "./MyKanban.vue?vue&type=template&id=a7226752&scoped=true&"
import script from "./MyKanban.vue?vue&type=script&lang=js&"
export * from "./MyKanban.vue?vue&type=script&lang=js&"
import style0 from "./MyKanban.vue?vue&type=style&index=0&id=a7226752&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7226752",
  null
  
)

export default component.exports